<template>
  <b-card
    class="brand-edit-wrapper"
  >
    <!-- form -->
    <b-form id="brandForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌全称"
            label-for="name"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="brand.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌昵称"
            label-for="short_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="short_name"
              size="sm"
              v-model="brand.short_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="ERP编码"
              label-for="erp_code"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="erp_code"
                size="sm"
                v-model="brand.erp_code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否白牌"
              label-for="is_white"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="is_white"
                v-model="brand.is_white"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="oneId"
              label-for="one_id"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="one_id"
                size="sm"
                v-model="brand.one_id"
                :readonly="user.role_id!=14"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">附件</div>
        </b-col>

        <b-col md="12">
          <b-form-group
            label-cols="1"
            label-cols-lg="1"
            label="图片"
            label-for="image"
            label-size="sm"
            class="mb-1"
          >
            <attachment-upload v-if="brand.loaded"
                               :attachment_id="'image'"
                               :id="brand.image"
                               :object_type="'test'"
                               :object_id="0"
                               @change="onUploaded"
            />
<!--            <b-form-input-->
<!--              id="image"-->
<!--              size="sm"-->
<!--              v-model="brand.image"-->
<!--              readonly-->
<!--            />-->
          </b-form-group>
        </b-col>
<!--        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                value="1"
                unchecked-value="0"
                id="state"
                v-model="brand.state"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>-->
<!--        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="brand.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="brand.modify_time"
            />
          </b-form-group>
        </b-col>-->
<!--                <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="下拉框"
            label-for="select"
            label-size="sm"
            class="mb-1"
          >
            <v-select
              id="select"
              v-model="brand.select"
              :options="selectOptions"
              class="select-size-sm"
            />
          </b-form-group>
        </b-col>-->
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import brandStore from './brandStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      brand: ref({}),
      selectOptions: [
        {value: '1', label: '111'},
        {value: '2', label: '222'},
        {value: '3', label: '333'},
      ],
      user: {},
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brand')) store.unregisterModule('brand')
    })

    const edit = function() {
      store.dispatch('brand/edit', {id: this.id}).then(res => {
        this.brand = res.data.data
      })
    }

    const view = function() {
      store.dispatch('brand/view', {id: this.id}).then(res => {
        this.brand = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      if (this.brand.name===undefined||this.brand.name===""){
        toast.error("请填写品牌全称!")
        return false
      }
      store.dispatch('brand/save', this.brand).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          this.$router.push({ name: 'apps-brand-list'});
        }else {
          toast.error(res.data.data)
        }

      })
    }

    const onUploaded = function (id, attachment) {
      this.brand[id] = attachment.attachment_id
    }

    return {
      edit,
      view,
      cancel,
      save,
      onUploaded,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;

    const userData = getUserData()
    this.user = userData
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-switch .custom-control-label::before{
  margin-top: 5px;
}
</style>
